<h6 *ngIf="normalRole" class="headh6">User Details</h6>

<div class="container-fluid rolespage">

  <div *ngIf="normalRole">


    <div class=" mt-3 ">
      <ul class="nav nav-tabs">
        <li [routerLink]="['/customer/roles','createdUsers']" class="mr-1" >
          <a class="nav-link" [class.active-tab]="viewType == 'user'"
            (click)="viewType = 'user'">Users</a></li>
        <li [routerLink]="['/customer/roles','definedRoles']" class="mr-1">
          <a class="nav-link"  [class.active-tab]="viewType == 'roles'"
            (click)="viewType = 'roles'">Roles</a></li>
        <li [routerLink]="['/customer/roles','vendorAdmin']" class="mr-1" >
          <a class="nav-link"  [class.active-tab]="viewType == 'vendorAdminUser'" (click)="viewType = 'vendorAdminUser'">Vendor Admin</a></li>
      </ul>
    </div>

    <div [ngSwitch]="viewType" class="bg-design-all tableDataDiv pt-2 pb-2">
      <!-- All users -->
      <div *ngSwitchCase="'user'">
        <div class="positionCreateUpload">
          <!-- <button class=" btnUpload mr-2">Export</button> -->
          <button class=" btnUpload mr-2" (click)="createCustomerUserPage()"><span class="f-16">+</span> Add New
            User</button>
        </div>
        <div *ngIf="initialView" class="mat-elevation-z8">

          <div>
            <div class="searchBar">
              <input class="searchInput" pInputText type="text" placeholder="Search..."
                (input)="userRole.filterGlobal($event.target.value, 'contains')" />
              <span class="material-icons searchIcon">
                search
              </span>
            </div>
            <p-table #userRole [value]="CustomerUserReadData" [paginator]="showPaginator" [rows]="row_customer" [first]="first_cust"
              [showCurrentPageReport]="true" styleClass="p-datatable-striped"
              [globalFilterFields]="['idUser','UserName','NameOfRole','entity_site_count','isActive','firstName','lastName']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10,25,50]" (onPage)="paginate($event,'cust')" >

              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="idUser">User ID</th>
                  <th pSortableColumn="firstName">Name</th>
                  <th pSortableColumn="NameOfRole">Role</th>

                  <th pSortableColumn="entity_site_count">No of Entity sites</th>
                  <th pSortableColumn="isActive">Activity Status</th>
                  <th class="w-30">Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-users>
                <tr>
                  <td>{{users.idUser}}</td>
                  <td>{{users.firstName}} {{users.lastName}}</td>
                  <td class="p-0">
                    <select class="form-control inputHeight f-12"
                      (change)="changeUserRole(users,users.NameOfRole)"
                      [(ngModel)]="users.NameOfRole">
                      <option *ngFor="let role of DisplayRoleName" [value]="role.NameOfRole">{{role.NameOfRole}}
                      </option>
                    </select>
                  </td>

                  <td><span style="margin-left: 16px;">{{users.entity_site_count}}</span></td>
                  <td *ngIf="users.isActive ==  1"><span style="color: #000000;">Activated</span></td>
                  <td *ngIf="users.isActive ==  0"><span
                      style="color: #FF3900;">Inactive</span></td>
                  <td>
                    <i class="fa fa-pencil-square-o actionsBtn" (click)="editUser(users)" aria-hidden="true"></i>
                    <button *ngIf="users.isActive ==  0" class="statusType btnUpload actveBtn"
                      style="background-color: #8EB67F;"
                      (click)="confirmationPopUp(users.idUser,'Activate')">Activate</button>
                    <button *ngIf="users.isActive ==  1" class="statusType btnUpload actveBtn"
                      style="background-color: #F08A6B;"
                      (click)="confirmationPopUp(users.idUser,'Deactivate')">Deactivate</button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6" class="p-t-30 text-center">
                    <img src="assets/Group 2691.png" width="260" alt="imageUrl"> <br>
                    No customers found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <!-- roles -->
      <div *ngSwitchCase="'roles'">
        <div class="positionCreateUpload">
          <button class=" btnUpload mr-2" (click)="createRole()"><span class="f-16">+</span> Create New Role</button>
        </div>
        <p-table [value]="DisplayRoleName" [paginator]="false" [rows]="10" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-striped">

          <ng-template pTemplate="header">
            <tr>
              <th style="text-align: left;" pSortableColumn="NameOfRole">Role Name</th>
              <th style="text-align: left;" pSortableColumn="Priority">Priority</th>
              <th style="text-align: center;">Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-users>
            <tr>
              <td style="text-align: left;padding: 10px;">{{users.NameOfRole}}</td>
              <td style="text-align: left;padding-left: 12px;">{{users.Priority}}</td>
              <td style="text-align: center;padding: 10px;">
                <!-- <p-toast></p-toast> -->
                <!-- (click)="confirm($event,users)"  -->
                <p-confirmPopup></p-confirmPopup>
                <i *ngIf="users.isDefault == 0" (click)="showDailog(users)" class="fa fa-trash-o actionsBtn mr-2" aria-hidden="true"></i>
                <i *ngIf="users.isDefault == 0" class="fa fa-pencil-square-o actionsBtn" (click)="editRole(users)" aria-hidden="true"></i>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6" class="p-t-30 text-center">
                <img src="assets/Group 2691.png" width="260" alt="imageUrl"> <br>
                No roles found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- vendor Admin users -->
      <div *ngSwitchCase="'vendorAdminUser'">
        <div class="positionCreateUpload">
          <button class=" btnUpload mr-2" (click)="displayAddUserDialog = true"><span class="f-16">+</span> Add
            Vendor Admin</button>
        </div>
        <div *ngIf="initialView" class="mat-elevation-z8">

          <div>
            <div class="searchBar" style="right: 120px;">
              <input class="searchInput" pInputText type="text" placeholder="Search..."
                (input)="userRole.filterGlobal($event.target.value, 'contains')" />
              <span class="material-icons searchIcon">
                search
              </span>
            </div>
            <p-table #userRole [value]="vendorAdminReadData" [paginator]="showPaginatorSp" [rows]="row_vendor" [first]="first_vendor"
              [showCurrentPageReport]="true" styleClass="p-datatable-striped"
              [globalFilterFields]="['idUser','firstName','lastName','NameOfRole','vendor_name','isActive']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10,25,50]" (onPage)="paginate($event,'vendor')">

              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="idUser">User ID</th>
                  <th pSortableColumn="firstName">Name</th>
                  <th pSortableColumn="NameOfRole">Role</th>

                  <th pSortableColumn="vendor_name">Vendor Name</th>
                  <th pSortableColumn="isActive">Activity Status</th>
                  <th class="w-30"></th>
                  <!-- <th pSortableColumn="rnk">Financial Approval Level</th> -->
                  <!-- <th>Action</th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-users>
                <tr>
                  <td>{{users.idUser}}</td>
                  <td>{{users.firstName}} {{users.lastName}}</td>
                  <td>{{users.NameOfRole}}
                  </td>

                  <td>{{users.vendor_name}}</td>
                  <td>
                    <span *ngIf="users.isActive ==  1" style="color: #000000;">Activated</span>
                    <span *ngIf="users.isActive ==  0" 
                      style="color: #FF3900;">Inactive</span>
                  </td>
                  <td>
                    <button class="btnUpload" [disabled]="users.isActive ==  0"
                    [ngStyle]="{ 'cursor' : users.isActive ==  0 ? 'not-allowed' : 'pointer' } "(click)="resetPasswordVendor(users.email)">Reset</button>
                    <button *ngIf="users.isActive ==  0" class="statusType btnUpload actveBtn"
                      style="background-color: #8EB67F;"
                      (click)="confirmationPopUp(users.idUser, 'Activate')">Activate</button>
                    <button *ngIf="users.isActive ==  1" class="statusType btnUpload actveBtn"
                      style="background-color: #F08A6B;"
                      (click)="confirmationPopUp(users.idUser,'Deactivate')">Deactivate</button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="p-t-30 text-center">
                    <img src="assets/Group 2691.png" width="260" alt="imageUrl"> <br>
                    No vendor admins found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- create new Role -->
  <div *ngIf="CreateNewRole">
    <div >
      <div class="pos_rel">
        <span class="goBackIn" (click)="cancelRoles()">
          <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
        </span>
        <h6 class="headh6 p-l-30">
          <span class="ml-35">{{roletype}}</span></h6>
      </div>
      <div class="createDivht">

        <div class="bg-design-all p-2 b-top-left">
          <div class="row">
            <div class="col-sm-4">
              <div>
                <input type="text" class="form-control w-100 inputForm mb-3 f-13" id="newRole" [(ngModel)]="newRoleName"
                  name="createNewRole" ngModel #createRole="ngModel" placeholder="Role Name"
                  [ngClass]="createRoleRequiredBoolean ? 'formInvalid':'inputForm'" required>
  
  
                <div style="color: red;margin-top: -15px;font-size: 13px;"
                  *ngIf="createRole.touched && !createRole.valid">
                  <div *ngIf="createRole.errors.required">Role name required</div>
                </div>
                <!-- <div *ngIf="createRoleRequiredBoolean" style="color: red; font-size: 13px;">Role name required</div> -->
              </div>
            </div>
  
            <div class="col-sm-4">
              <div>
                <input type="number" [(ngModel)]='role_priority' 
                name="rolePriority" ngModel #createRolePriority="ngModel" placeholder="Role Priority"
                  class="form-control inputForm mb-3 f-13 w-100" required>
              </div>
            </div>
  
            <div class="col-sm-4">
              <!-- <div>
                <input type="text" [(ngModel)]='newRoleDescription' placeholder="Role Description"
                  class="form-control inputForm mb-3 f-13 w-100">
              </div> -->
            </div>
          </div>
  
          <div class="f-14B roleAccess">Role Access</div>
          <div class="row">
            <div class="col-sm-4">
              <div class="mb-3">
                <div class="permisionDiv ">
                  <div> <span class="textth">Add/Modify users</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="AddorModifyUserBoolean" (change)="changeUserPermission($event)">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
  
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth">Add/Modify user role</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="userRoleBoolean" (change)="changeUserRolePermission($event)">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
  
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth">Add/Upload new Invoice</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="invoiceBoolean" (change)="changeInvoicePermission($event)">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>

              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth"> Service invoices batch trigger</span> 
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="spTriggerBoolean" (change)="changeSpTriggerPermission($event)">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
              
              
  
            </div>
  
            <div class="col-sm-4">
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth">Vendor/ServiceProvider pages access</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="vendorPageBoolean" [(ngModel)]="vendorPageBoolean">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
              
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth">Exception page access</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="exceptionPageBoolean" [(ngModel)]="exceptionPageBoolean">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
              
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth"> Settings page access</span> 
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="settingsPageBoolean" [(ngModel)]="settingsPageBoolean">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth"> Create GRN page access</span> 
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="GRNPageBoolean" [(ngModel)]="GRNPageBoolean">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
            </div>
  
            <div class="col-sm-4">
              
              <div class=" mb-3">
                <div class="permisionDiv">
                  <div> <span class="textth"> Configuration portal access</span> 
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="configAccessBoolean" [(ngModel)]="configAccessBoolean">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="permisionDivdescription">Description</div>
              </div>
              <div>
                <div class="permisionDiv">
                  <div> <span class="textth">Invoices Permission:</span>
  
                  </div>
                </div>
                <div>
                  <div class="permisionDiv12">
                    <div> <span class="f-13">View</span>
                      <span class="f-right">
                        <label class="switch">
                          <input type="checkbox" [checked]="viewInvoiceBoolean" (change)="changeViewInvoice($event)">
                          <span class="slider round"></span>
                        </label>
                      </span>
                    </div>
                    <div class="clrDes">Description</div>
                  </div>
                  <div class="permisionDiv12 b-btm">
                    <div> <span class="f-13">Edit</span>
                      <span class="f-right">
                        <label class="switch">
                          <input type="checkbox" [checked]="editInvoiceBoolean" (change)="changeEditInvoice($event)">
                          <span class="slider round"></span>
                        </label>
                      </span>
                    </div>
                    <div class="clrDes">Description</div>
                  </div>
                  <!-- <div class="permisionDiv12 b-btm">
                    <div> <span class="f-13">Change approve</span>
                      <span class="f-right">
                        <label class="switch">
                          <input type="checkbox" [checked]="changeApproveBoolean"
                            (change)="changeChangeApproveInvoice($event)">
                          <span class="slider round"></span>
                        </label>
                      </span>
                    </div>
                    <div class="clrDes">Description</div>
                  </div> -->
                  <div *ngIf="financeapproveDisplayBoolean" class="permisionDiv12 b-btm">
                    <div> <span class="f-13">Finance approve</span>
                      <span class="f-right">
                        <label class="switch">
                          <input type="checkbox" [checked]="financeApproveBoolean"
                            (change)="changeFinanceApproveInvoice($event)">
                          <span class="slider round"></span>
                        </label>
                      </span>
                    </div>
                    <div class="clrDes">Description</div>
                  </div>
                </div>
                <div *ngIf="financeApproveBoolean && financeapproveDisplayBoolean">
                  <!-- <div class="permisionDiv12">
                    <div> 
                        <label ><span class="f-13">Priority</span>
                          <input type="number" class="inputForm form-control w-100 f-13" >
                        </label>
                    </div>
                  </div> -->
                  <div class="mt-2">
                    <div> 
                        <label class="f-13 w-100" >Max Approval Amount <span style="color: #f35353;">(*required field)</span>
                          <input type="number" [(ngModel)]="max_role_amount"  style="width:100%" class="inputForm form-control w-100 f-13" >
                        </label>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
          <div class="btnAction">
            <button class="btn btnVender bg-btn-cancel mr-3" (click)="cancelRoles()">Cancel</button>
            <button *ngIf="saveRoleBoolean" class="btn btnVender bg-btn-success" [disabled]="createRole.invalid || createRolePriority.invalid"
              [ngStyle]="{'cursor':createRole.invalid ? 'not-allowed':'pointer'}" (click)="saveRoles()">Create</button>
            <button *ngIf="!saveRoleBoolean" class="btn btnVender bg-btn-success" (click)="updateRoleInfoData()">Update</button>
          </div>
        </div>

      </div>
    </div>
    <ngx-spinner bdColor="rgba(251, 251, 251, 0.8)" size="medium" color = "#070900" [fullScreen]="false" type="ball-spin-clockwise">
      <p style="color: rgb(0, 0, 0)"> </p>
    </ngx-spinner>
  </div>

  <!--Edit user / create user  -->
  <div *ngIf="editUserdata">
    <div class="pos_rel">
      <span class="goBackIn" (click)="canceleditUser()">
        <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
      </span>
      <h6 class="headh6 p-l-30" *ngIf="headerEdituserboolean">
        <span class="ml-35">Edit User</span> </h6>
      <h6 class="headh6 p-l-30" *ngIf="!headerEdituserboolean">
        <span class="ml-35">Create New User</span></h6>
    </div>
    <div class=" bg-design-all p-2 ml-1 mr-1 b-top-left" >
      <div class="row" style="min-height: 66vh;">
        <div class="col-sm-5">
          <div>
            <div>
              <div *ngIf="!headerEdituserboolean">
                <label class="label-head">User Name</label>
                <input type="text" 
                      class="form-control inputForm mb-3 f-13" 
                      name="username" 
                      ngModel 
                      #UserName="ngModel"
                      [(ngModel)]="userName" 
                      (keyup)="userCheck($event.target.value)" 
                      [ngStyle]="{'border-left': userBoolean ? '5px solid green' : userNotBoolean ? '5px solid red':'' } "
                      placeholder="User name" 
                      minlength="6"
                      required>
                <!-- <span *ngIf="userBoolean" class="f-13 mt-10" style="color: green;">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                </span>
                <span *ngIf="userNotBoolean" class="f-13 mt-10" style="color: #f35353;">
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </span> -->
                <div *ngIf="UserName.errors &&(UserName.touched || UserName.dirty)" class="alertDiv">
                  <div [hidden]="!UserName.errors.minlength">
                    UserName should contains atleast 6 letters.
                  </div>
                  <div [hidden]="!UserName.errors.required">
                    **Required field
                  </div>
                </div>
              </div>
  
              <label class="label-head">First Name</label>
              <input type="text" class="form-control inputForm mb-3 f-13" name="FirstName" ngModel #firstname="ngModel"
                [(ngModel)]="firstName" placeholder="First name" required>
  
              <div *ngIf="firstname.invalid &&(firstname.touched || firstname.dirty)" class="alertDiv">
                <div [hidden]="!firstname.errors.required">
                  **Required field
                </div>
              </div>
  
              <label class="label-head">Last Name</label>
              <input type="text" class="form-control inputForm mb-3 f-13" name="Lastname" ngModel #LastName="ngModel"
                [(ngModel)]="lastName" placeholder="Last name" required>
  
              <div *ngIf="LastName.invalid &&(LastName.touched || LastName.dirty)" class="alertDiv">
                <div [hidden]="!LastName.errors.required">
                  **Required field
                </div>
              </div>
  
              <label class="label-head">User Email</label>
              <input type="email" 
                      name="email" 
                      ngModel 
                      #mail="ngModel" 
                      class="form-control inputForm mb-3 f-13"
                      [(ngModel)]="userEmail" 
                      placeholder="Email" 
                      pattern="[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" 
                      required>
              <div *ngIf="mail.invalid &&(mail.touched || mail.dirty)" class="alertDiv">
                <div [hidden]="!mail.errors?.pattern">
                  Not a valid email
                </div>
                <div [hidden]="!mail.errors.required">
                  **Required field
                </div>
              </div>
  
              <label class="label-head">Role Name</label>
              <select class="form-control inputForm f-12 mb-3" 
                      name="editrole" 
                      ngModel 
                      #editRole="ngModel"
                      [(ngModel)]="editRoleName" 
                      (change)="selectRole(editRoleName)" 
                      required>
                <option *ngFor="let role of DisplayRoleName" [value]="role.NameOfRole">{{role.NameOfRole}}</option>
              </select>
  
              <div *ngIf="editRole.invalid &&(editRole.touched || editRole.dirty)" class="alertDiv">
                <div [hidden]="!editRole.errors.required">
                  **Required field
                </div>
              </div>
  
              <!-- <div *ngIf="editRoleName == 'Finance Controller'">
                <label class="label-head">Amount</label>
                <input type="text" class="form-control inputForm mb-3 f-13" [(ngModel)]="Flevel" name="fLevel"
                  placeholder="Financial Level" >
              </div> -->
  
              <button *ngIf="headerEdituserboolean" class="btn btn-block hard_reset bg-btn"
                (click)="resetPassword()">{{resetBtnText}}</button>
  
  
            </div>
  
          </div>
        </div>
        <div class="col-sm-7">
  
          <div>
            <div class="f-13 label-head">Select Entity</div>
            <!-- <p-autoComplete  (onSelect)="onSelectEntity($event)"
                            [suggestions]="filteredEntities" 
                            (completeMethod)="filterEntity($event)" 
                            field="EntityName"
                            [dropdown]="true">
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.EntityName}}</div>
                </div>
              </ng-template>
            </p-autoComplete> -->

              <p-multiSelect 
                [options]="entityList" 
                [(ngModel)]="entitySelection_user" 
                (onChange) = "onSelectEntity($event)"
                defaultLabel="Select entity" 
                inputStyleClass="form-control inputBox selectpicker"
                optionLabel="EntityName">
            </p-multiSelect>
          </div>
          <!-- <div class="d-flex mt-2 mb-3">
            <div class="mr-2">
              <div class="f-13 label-head">Select Entitybody</div>
              <p-autoComplete  inputStyleClass="minWidthOverride"
                              (onSelect)="onSelectEntityBody($event)" 
                              [suggestions]="filterDentityBody"
                              (completeMethod)="filterEntityBody($event)" 
                              field="EntityBodyName" 
                              [dropdown]="true">
                <ng-template let-country pTemplate="item">
                  <div class="country-item">
                    <div>{{country.EntityBodyName}}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div>
              <div class="f-13 label-head">Select Entity Department</div>
              <p-autoComplete  inputStyleClass="minWidthOverride"
                              (onSelect)="onSelectEntityDept($event)" 
                              [suggestions]="filterDentityDept"
                              (completeMethod)="filterEntityDept($event)" 
                              field="DepartmentName" 
                              [dropdown]="true">
                <ng-template let-country pTemplate="item">
                  <div class="country-item">
                    <div>{{country.DepartmentName}}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div> -->
  
          <div>
            <div class="f-14B">List of Entities that user can access</div>
            <ul class="p-0 ul-list">
              <li *ngFor="let value of selectedEntitys; let i = index;" class="listEntity">
                <span class="text_elipse">{{value.entity}}--{{value.entityBody}}--{{value.entityDept}}</span>
                <span class="material-icons removeList" (click)="onRemove(i,value)">
                  cancel
                  </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btnAction">
        <button class="btn btnVender bg-btn-cancel mr-3" (click)="canceleditUser()">Cancel</button>
        <button *ngIf="!headerEdituserboolean" class="btn btnVender bg-btn-success"
          type="submit"
          (click)="toCreateUser()">Create</button>
        <button *ngIf="headerEdituserboolean" 
                class="btn btnVender bg-btn-success"
                [ngStyle]="{'cursor':( editRole.invalid || mail.invalid  )? 'not-allowed':'pointer'}"
                [disabled]=" editRole.invalid || mail.invalid " 
                (click)="UpdateUser()">
                Update
        </button>
  
          <!-- [disabled]="editRole.invalid || mail.invalid || UserName.invalid || firstname.invalid || LastName.invalid    "
          
          [ngStyle]="{'cursor':( !editRole.valid || !mail.valid || !UserName.valid || !firstname.valid || !LastName.valid)? 'not-allowed':'pointer'}" -->
      </div>
    </div>

  </div>
</div>

<!-- Delete Cnfirmation Dialog -->
<p-dialog header=" " [(visible)]="displayResponsive" [breakpoints]="{'960px': '75vw'}" [style]="{width: '30vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="deleteDiv">
    <div>
      <i class="pi pi-exclamation-triangle" style="font-size: 4rem;color: red;"></i>
    </div>
    <div class="deleteDivText">
      {{deleteBtnText}}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button *ngIf="deleteRoleBoolean" class="btn btnVender bg-btn-success mr-3" (click)="DeleteRole()">Yes, Delete this
      Role</button>
    <button *ngIf="deactivateBoolean" class="btn btnVender bg-btn-success mr-3" (click)="activa_deactive()">Yes</button>
    <button *ngIf="vendorResetBtnBoolean" class="btn btnVender bg-btn-success mr-3" (click)="resetPassVendorAPI()">Yes</button>
    <button *ngIf="userResetBtnBoolean" class="btn btnVender bg-btn-success mr-3" (click)="resetPasswordUserAPI()">Yes</button>
    <button class="btn btnVender bg-btn-cancel" (click)="displayResponsive=false">Cancel</button>

  </ng-template>
</p-dialog>

<!-- add vendor super user Dialog -->
<p-dialog header="Add Vendor Admin" [(visible)]="displayAddUserDialog" [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '31vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="p-auto">
    <label class="inviteLabel">Select Vendor</label>
    <p-autoComplete
    placeholder="Select Vendor"
    (onSelect)="selectVendor($event)"
    [group]="false"
    [(ngModel)]="vendorCreate"
    [suggestions]="filteredVendors"
    (completeMethod)="filterVendor($event)"
    field="VendorName"
    inputStyleClass="form-control inputBox selectpicker"
    [dropdown]="true"
    name="vendrCreate" ngModel #vendrCreate="ngModel"
  >
    <ng-template let-group pTemplate="group">
      <div class="flex align-items-center">
        <span class="f-12">{{ group.VendorName }}</span>
      </div>
    </ng-template>
  </p-autoComplete>
    <!-- <input list="Vendors" name="Vendor" id="Vendor" class="form-control inputBox selectpicker"  [(ngModel)]="vendorCreate" name="vendrCreate" ngModel #vendrCreate="ngModel"
    (change)="selectVendor(vendorCreate)" required>
    <datalist id="Vendors">
      <option class="f-11"  *ngFor="let vendor of vendorList" [value]="vendor.VendorName">
    </datalist> -->
    <div *ngIf="vendrCreate.errors &&(vendrCreate.touched || vendrCreate.dirty)" class="alertDiv">
      <div [hidden]="!vendrCreate.errors.required">
        **Required field
      </div>
    </div>

    <div>
      <label class="inviteLabel" for="entity">Select Entity</label>
      <p-multiSelect 
        [options]="entityForVendorCreation" 
        [(ngModel)]="entitySelection" 
        (onChange) = "onSelectedEntityCode($event)"
        defaultLabel="Select entity" 
        inputStyleClass="form-control inputBox selectpicker"
        optionLabel="EntityName">
      </p-multiSelect>
        
    </div>

    <label class="inviteLabel" for="username">Username</label>
    <input type="text" 
          id="username" 
          class="form-control inputBox" 
          name="userCreate" 
          ngModel 
          #userCreate="ngModel"
          [(ngModel)]="createUserName" 
          (keyup)="userCheck($event.target.value)" 
          minlength="6" 
          [ngStyle]="{'border-left': userBoolean ? '5px solid green' : userNotBoolean ? '5px solid red':'' } "
          required>

    <!-- <span *ngIf="userBoolean" class="f-13 mt-user" style="color: green;">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </span>
    <span *ngIf="userNotBoolean" class="f-13 mt-user" style="color: #f35353;">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </span> -->
    <div *ngIf="userCreate.errors &&(userCreate.touched || userCreate.dirty)" class="alertDiv">
      <div [hidden]="!userCreate.errors.minlength">
        UserName should contains atleast 6 letters.
      </div>
      <div [hidden]="!userCreate.errors.required">
        **Required field
      </div>
    </div>

    <label class="inviteLabel">First Name</label>
    <input type="text" class="form-control inputBox" name="fnameCreate" ngModel #fnameCreate="ngModel"
      [(ngModel)]="createVfirstName" required>

    <div *ngIf="fnameCreate.errors &&(fnameCreate.touched || fnameCreate.dirty)" class="alertDiv">
      <div [hidden]="!fnameCreate.errors.required">
        **Required field
      </div>
    </div>

    <label class="inviteLabel">Last Name</label>
    <input type="text" class="form-control inputBox" name="lnameCreate" ngModel #lnameCreate="ngModel"
      [(ngModel)]="createVlastName" required>

    <div *ngIf="lnameCreate.errors &&(lnameCreate.touched || lnameCreate.dirty)" class="alertDiv">
      <div [hidden]="!lnameCreate.errors.required">
        **Required field
      </div>
    </div>

    <label class="inviteLabel" for="email"> Email ID</label>
    <input type="email" id="email" pattern="[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" class="form-control inputBox"
      name="EmailCreate" ngModel #createMail="ngModel" [(ngModel)]="emailIdInvite" required>
    <div *ngIf="createMail.errors &&(createMail.touched || createMail.dirty)" class="alertDiv">
      <div [hidden]="!createMail.errors?.pattern">
        Please enter valid email.
      </div>
      <div [hidden]="!createMail.errors.required">
        **Required field
      </div>
    </div>



    <!-- <label class="inviteLabel">Role</label>
    <input type="text" value="Vendor Super User" class="form-control inputBox" disabled > -->

  </div>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btnVender bg-btn-cancel mr-3" (click)="displayAddUserDialog = false">Cancel</button>
    <button type="button" class="btn btnVender bg-btn-success"
      [ngStyle]="{'cursor':(vendrCreate.invalid  || fnameCreate.invalid || lnameCreate.invalid|| createMail.invalid || userCreate.invalid || userNotBoolean)? 'not-allowed':'pointer'}"
      [disabled]="vendrCreate.invalid || fnameCreate.invalid|| lnameCreate.invalid ||createMail.invalid || userCreate.invalid || userNotBoolean "
      (click)="createVendorSuprUser()">Add Vendor Super User</button>

  </ng-template>
</p-dialog>

<p-toast></p-toast>